// import React from 'react';
// import Loadable from 'react-loadable';
//
// import Loading from '../Loading';
//
// export default Loadable({
//   loader: () => import('./Success'),
//   loading: () => <Loading />,
//   delay: 300
// });

export {default} from './Success';
